import React, { useEffect } from "react"
import * as myStyles from "./404.module.css"
import Layout from "../components/Layout"
import Seo from "../components/Seo/Seo"

const NotFoundPage = () => {
    useEffect(() => {
        if (window !== undefined) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ 'event': '404-page-visited' })
        }
    }, [])
    
    return (
        <Layout>
            <Seo
            title="404: Not found"
            description="Page unavailable"
            image={null}
            pathname="404"
            />

            <div className="relative h-screen bg-zone-1">
            <div className={myStyles.notFound}>
                <div className={myStyles.notFoundInner}>
                <h1>Oops!</h1>
                </div>
                <h2 className="font-grotesk uppercase mt-0 text-zone-1 text-4xl font-bold mb-4">404 - Page non trouvée</h2>
                <p className="mb-5 mt-0 text-lg font-signika text-zone-2">La page que vous recherchez peut avoir été supprimée si elle a été renommée ou est temporairement indisponible.</p>
                <a href="/" className="rounded-3xl uppercase font-grotesk no-underline bg-zone-14 inline-block py-4 px-10 text-white font-semibold transition duration-300 hover:text-zone-1 hover:bg-zone-12">Aller à la page d'accueil</a>
            </div>
            </div>

        </Layout>
    )
}

export default NotFoundPage
